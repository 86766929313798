import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import ReactGA from 'react-ga';
import './assets/css/App.css';

import About from './About';
import Audiobooks from './Audiobooks';
import Connect from './Connect';
import Ebooks from './Ebooks';
import Film from './Film';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './Home';
import NotFound from './NotFound';
import PublishingSystems from './PublishingSystems';
import ThankYou from './ThankYou';
import WebPlatforms from './WebPlatforms';

ReactGA.initialize('UA-40922044-1');
ReactGA.pageview(window.location.pathname);

class App extends Component {
  render() {
    let history = createBrowserHistory();

    history.listen(location => {
      setTimeout(() => {
        if (location.action === 'POP') {
          return;
        }

        window.scrollTo(0, 0);
      });
    });

    return (
      <Router history={history}>
        <div className="App">
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about-us" component={About} />
            <Route exact path="/audiobooks" component={Audiobooks} />
            <Route exact path="/connect" component={Connect} />
            <Route exact path="/ebooks" component={Ebooks} />
            <Route exact path="/film" component={Film} />
            <Route exact path="/publishing-systems" component={PublishingSystems} />
            <Route exact path="/thank-you" component={ThankYou} />
            <Route exact path="/web-platforms" component={WebPlatforms} />
            <Route path="*" component={NotFound} />
          </Switch>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;