import React, { Component } from 'react';
import styled, {css} from 'styled-components';

import SectionSubheading from './SectionSubheading';

class ContentRow extends Component {
  render() {
    return (
      <section className={`${ this.props.className + ' grid'}`}>
        <figure className="grid__col-md-6 grid__col-12 content__image">
          <img src={this.props.src} alt={this.props.alt} />
        </figure>
        <article className="grid__col-md-6 grid__col-12 content__text">
          <SectionSubheading text={this.props.heading} />
          <p>{this.props.text}</p>
          {this.props.children}
        </article>
      </section>
    );
  }
}

export default styled(ContentRow)`
  margin: 80px 0 0;
  .content__image {
    align-items: center;
    justify-content: center;
    background: var(--background--grey);
  }
  .content__text {
    padding: 70px 60px;
    &:before {
      position: absolute;
      top: 40px;
      left: -40px;
      content: '';
      border-top: 40px solid transparent;
      border-bottom: 40px solid transparent;
      border-right: 40px solid var(--text-color--light);
    }
    .section__subheading,
    p {
      max-width: 570px;
    }
    .section__subheading {
      width: 100%;
      margin: 0 0 15px;
    }
    p:not(:last-child) {
      margin: 0 0 25px;
    }
    @media only screen and (max-width: 500px) {
      padding: 45px 7.5%;
    }
  }
  ${props => props.alignRight && css`
    @media only screen and (min-width: 992px) {
      .content__image {
        order: 2;
      }
      .content__text {
        order: 1;
        align-items: flex-end;
        &:before {
          left: auto;
          right: -40px;
          border-right: 0;
          border-left: 40px solid var(--text-color--light);
        }
      }
    }
  `}
  ${props => props.imgFullSize && css`
    .content__image {
      overflow: hidden;
      img {
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        max-width: none;
      }
    }
  `}
`;