import React, { Component } from 'react';
import styled, {css} from 'styled-components';

class SectionHeading extends Component {
  render() {
    return (
      <h2 className={`${ this.props.className+ ' section__heading' }`}>{this.props.text}</h2>
    );
  }
}

export default styled(SectionHeading)`
  font-size: 18px;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: ${props => props.center ? 'center' : ''};
  margin: ${props => props.extraSpacing ? '40px auto' : ''};
  color: ${props => props.highlighted ? 'var(--text-color--accent)' : 'var(--text-color--dark)'};
  ${props => props.bgGrey && css`
    text-align: center;
    padding: 25px 0;
    background: var(--background--grey);
  `}
`;