import React, { Component } from 'react';
import styled, {css} from 'styled-components';

class ImageGrid extends Component {
  render() {
    return (
      <ul className={`${ this.props.className + ' grid'}`}>
        {this.props.children}
      </ul>
    );
  }
}

export default styled(ImageGrid)`
  li {
    overflow: hidden;
    img {
      transition: all .5s;
    }
    div {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      background: rgba(4, 159, 218, .5);
      transition: all .5s;
    }
    h4 {
      opacity: 0;
      width: 180px;
      font-size: 18px;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 1px;
      padding: 2px 0; 
      color: var(--text-color--light);
      border-top: 1px solid var(--text-color--light);
      border-bottom: 1px solid var(--text-color--light);
      transition: all .5s;
      span {
        display: block;
      }
    }
    &:hover {
      img {
        transform: scale(1.15);
        filter: blur(2px);
      }
      div {
        background: rgba(4, 159, 218, .75);
      }
      h4 {
        opacity: 1;
        padding: 10px 0; 
      }
    }
    a {
      display: flex;
    }
    img {
      width: 100%;
      max-width: none;
    }
    ${props => props.about && css`
      &.team--join {
        a {
          position: relative;
          display: flex;
          width: 100%;
          height: 100%;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: var(--accent-color);
          color: var(--text-color--light);
          padding: 90px;
        }
        h4,
        p {
          position: absolute;
          width: 200px;
        }
        h4 {
          opacity: 1;
          border: 0;
          transition-delay: .5s;
        }
        p {
          opacity: 0;
          font-size: 20px;
          text-align: center;
          line-height: 1.1;
          transition: all .5s;
        }
        &:hover {
          h4 {
            opacity: 0;
          }
          p {
            opacity: 1;
            transition-delay: 1.5s;
          }
        }
      }
    `}
    ${props => props.home && css`
      &:nth-child(1) div {
        background: rgba(4, 159, 218, .5);
        &:hover {
          background: rgba(4, 159, 218, .75);
        }
      }
      &:nth-child(2) div {
        background: rgba(4, 159, 218, .5);
        &:hover {
          background: rgba(4, 159, 218, .75);
        }
      }
      &:nth-child(3) div {
        background: rgba(58, 80, 98, .5);
        &:hover {
          background: rgba(58, 80, 98, .75);
        }
      }
      &:nth-child(4) div {
        background: rgba(20, 218, 255, .5);
        &:hover {
          background: rgba(20, 218, 255, .75);
        }
      }
      &:nth-child(5) div {
        background: rgba(14, 112, 184, .5);
        &:hover {
          background: rgba(14, 112, 184, .75);
        }
      }
      &.service__bookstore:hover img {
        filter: blur(0px);
      }
    `}
  }
`;