import React, { Component } from 'react';
import styled from 'styled-components';

class Menu extends Component {
  render() {
    return (
      <ul className={`${ this.props.className}`}>
        {this.props.children}
      </ul>
    );
  }
}

export default styled(Menu)`
  font-size: 14px;
  font-weight: var(--fw--light);
  padding: 0 5%;
  color: var(--text-color--light);
  li:not(:last-child) {
    margin: 0 0 10px;
  }
  a {
    color: inherit;
    &:hover {
      color: var(--text-color--accent);
    }
  }
`;