import React, { Component } from 'react';
import styled from 'styled-components';

const Label = styled.label`
  display: block;
  font-size: 14px;
  font-weight: var(--fw--bold);
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 10px;
  color: var(--text-color--dark);
`;

class FormField extends Component {
  render() {
    return (
      <fieldset className={`${ this.props.className}`}>
        <Label>{this.props.label}</Label>
        {this.props.children}
      </fieldset>
    );
  }
}

export default styled(FormField)`
  margin: 0 0 15px;
  .form__input {
    display: block;
    width: 100%;
    font-size: 16px;
    padding: 14px;
    background: var(--background--grey);
    border: 1px solid transparent;
    outline: 0;
    &:focus {
      border-color: var(--accent-color);
    }
  }
`;