import React, { Component } from 'react';
import styled from 'styled-components';

import Button from './components/Button';
import Container from './components/Container';
import ContentRow from './components/ContentRow';
import SectionCTA from './components/SectionCTA';
import SectionHeading from './components/SectionHeading';
import SectionHero from './components/SectionHero';
import SectionIntro from './components/SectionIntro';
import SectionValues from './components/SectionValues';
import TextBox from './components/TextBox';

import booksite1 from './assets/img/web/booksite--1.png';
import booksite2 from './assets/img/web/booksite--2.jpg';
import platformsAuthors from './assets/img/web/platforms--authors.png';
import platformsPublishers from './assets/img/web/platforms--publishers.png';
import webPlatformsFeatures from './assets/img/web/web-platforms__features.jpg';

const SectionFeatures = styled.section`
  padding: 60px 0;
  background: var(--background--grey);
  img {
    display: block;
    width: 85%;
    max-width: 1000px;
    margin: 45px auto 0;
  }
`;

class WebPlatforms extends Component {
  componentDidMount() {
    document.title = "Bright Wing Media | Web Platforms";
  }

  render() {
    return (
      <main className={`${ this.props.className }`}>
        <SectionHero webPlatforms heading="Bright Wing Web Platforms" />
        <SectionIntro heading="Greatness needs occasion">
          <p>Our web platforms for authors and publishing initiatives are made with love and respect for the content. We offer designed and developed websites specific to the publishing industry. These websites integrate audiobooks, trailers, film material, and a browser-based reading system called Booksites. Our web platforms are made for authors and books (of all kinds) with careful attention to detail, typography, and beauty. We’ve even developed a WordPress template for small publishers - discover it below!</p>
          <p>Bright Wing’s multiple services blend seamlessly as a web platform. We are able to develop your short film, your audiobook, and the payment and reading systems all at once and into one website; and sometimes we develop web platforms one service at a time. We have designers and developers who will work with you and find every way to showcase your ideas, publications, and to give you a home on the web.</p>
        </SectionIntro>
        <ContentRow
          src={platformsAuthors}
          alt="Author Platforms - Preview Desktop"
          heading="Web platforms for authors"
          text="Authors need readers and readers need authors to have web platforms. A vibrant online presence creates an opportunity for readers to discover authors and new books. Bright Wing offers everything you need to create this platform: web development tailored for the publishing industry, video production, design & layout, audiobooks, print books, and even a custom online reading system for your book. We specialize in platforms for authors and we give you a unique and powerful online stage to speak to your audience and for your audience to discover you!"
        >
          <Button url="mailto:aloha@brightwing.ca" text="Contact Us" />
        </ContentRow>
        <ContentRow
          alignRight
          src={platformsPublishers}
          alt="Author Platforms - Preview Site"
          heading="Web platforms for publishers"
          text="Seven years. That is how long we have worked with publishers, authors and publishing organizations. We know what publishers need, and we know every aspect of the publishing industry - from submissions to editing to production and distribution, and all the way to marketing. We know the language, the needs, and we know the budgets of publishers around the world. We also know how to build a web platform for our industry, and we do so with love, care, hard work, and lots of listening skills. Each online publishing platform can be as robust and feature-rich as you wish! Give us a call (778 874 0198) and ask us all the questions!"
        />
        <TextBox
          bgGrey
          heading="Check out our Wordpress Template for Publishers:"
          text="You can also check out our WordPress template built specifically for small or new publishers and which has some nifty features: For instance, do you have a CSV catalogue of your publishing assets? One click and the entire thing (including ISBNs!) will be neatly laid out in the bookstore section of your website. You can customize a lot of the template. If you want anything unique to you (audiobook integration anyone?), get in touch and we can modify this for you!"
        >
          <Button url="/" target="_blank" text="Publisher’s Template" />
        </TextBox>
        <Container>
          <SectionHeading extraSpacing text="Booksites: The reading system for your browser" />
        </Container>
        <SectionValues
          img1={booksite1}
          heading1=""
          text1="A booksite is an enhanced digital book; it is portable, web-hosted, and beautifully designed. Bright Wing Books developed this kind of digital publication because we wanted our authors and publishers to have full-control over their book. Normally, an ebook is uploaded to a third-party distributor so that readers can purchase the book and download it for reading onto their reading devices. The distributors charge a fee, of course, and the ebook itself is subjected to a very limited code-base which prevents us from fully designing the book so that it looks beautiful. Oh, and you also need to buy their device to read it."
          img2={booksite2}
          heading2=""
          text2="So, we developed a booksite so that an author and publisher can self-host their ebook on their website and so that the ebook can be found, purchased and read by any one with any device with a browser. This means authors and publishers receive all the profit and the reader has the best experience possible without needing a special e-reader. In one package, an author has a website, an ebook, and and their very own reading interface. Best of all, the author keeps all the profits, even while also distributing the book to the third-party, app-specific, device-dependent, royalty-capturing stores. We think it is a win/win for authors and publishers alike."
        />
        <SectionFeatures>
          <SectionHeading center text="Booksites are packed with features" />
          <img src={webPlatformsFeatures} alt="List of booksite features" />
        </SectionFeatures>
        <SectionCTA
          displayCTA2
          text="Interested in a web platform? Want to learn more?"
          btn1Text="Get in touch!"
          btn1Url="mailto:aloha@brightwing.ca"
          btn2Text="Visit our latest booksite"
          btn2Url="https://brightwingbooks.booksites.ca/"
          btn2Target="_blank"
        />
      </main>
    );
  }
}

export default WebPlatforms;