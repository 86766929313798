import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import Container from './Container';
import Menu from './Menu';
import MenuBookstore from './MenuBookstore';
import MenuSocial from './MenuSocial';

class HeaderMenu extends Component {
  render() {
    return (
      <nav className={`${ this.props.className}`}  onClick={this.props.onClick}>
        <Container className="grid">
          <Menu className="grid__col-md-2 grid__col-sm-4 grid__col-12">
            <li>
              <NavLink to="/"><strong>Home</strong></NavLink>
            </li>
            <li>
              <NavLink to="/about-us"><strong>About Us</strong></NavLink>
            </li>
            <li>
              <NavLink to="/connect"><strong>Connect</strong></NavLink>
            </li>
          </Menu>
          <Menu className="grid__col-md-3 grid__col-sm-4 grid__col-12">
            <li>
              <NavLink to="/ebooks"><strong>Ebooks</strong></NavLink>
            </li>
            <li>
              <NavLink to="/audiobooks"><strong>Audiobooks</strong></NavLink>
            </li>
            <li>
              <NavLink to="/film"><strong>Film</strong></NavLink>
            </li>
            <li>
              <NavLink to="/web-platforms"><strong>Web Platforms</strong></NavLink>
            </li>
            <li>
              <NavLink to="/publishing-systems"><strong>Publishing Systems</strong></NavLink>
            </li>
          </Menu>
          <Menu className="grid__col-md-3 grid__col-12 menu--store">
            <MenuBookstore />
          </Menu>
          <div className="grid__col-md-4 grid__col-12 container__menu--social">
            <MenuSocial />
          </div>
        </Container>
      </nav>
    );
  }
}

export default styled(HeaderMenu)`
  position: fixed;
  top: 96px;
  left: 0;
  width: 100%;
  padding: 50px 0;
  background: var(--background--header);
  transform: translate(0, -200%);
  transition: all .75s;
  .container__menu--social {
    width: 29%;
    align-self: flex-end;
    margin: 0 0 0 auto;
    padding: 25px 0 0;
    border-top: 1px solid var(--text-color--accent);
  }
  &.menu--show {
    transform: translate(0, 0);
  }
  @media only screen and (max-width: 991px) {
    .menu--store,
    .container__menu--social {
      display: none;
    }
  }
  @media only screen and (min-width: 992px) {
    .container > ul {
      border-right: 1px solid var(--text-color--accent);
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .container > ul:first-of-type {
      border-right: 1px solid var(--text-color--accent);
    }
  }
  @media only screen and (max-width: 767px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
    ul {
      align-items: center;
      padding: 30px 0;
      &:first-child {
        max-width: 70px;
        border-bottom: 1px solid var(--text-color--accent);
      }
    }
  }
  @media only screen and (max-width: 500px) {
    top: 83px;
  }
`;