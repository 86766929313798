import React, { Component } from 'react';
import styled from 'styled-components';

import Button from './components/Button';
import Container from './components/Container';
import SectionSubheading from './components/SectionSubheading';
import SectionHero from './components/SectionHero';

const SectionContent = styled.section`
  padding: 120px 0;
  .btn {
    margin: 45px 0 0;
  }
`;

class NotFound extends Component {
  componentDidMount() {
    document.title = "Bright Wing Media | Page not found";
  }

  render() {
    return (
      <main className={`${ this.props.className }`}>
        <SectionHero notFound heading="Page not found" />
        <SectionContent>
          <Container>
            <SectionSubheading text="Oops..."/>
            <p>Sorry, we are unable to find the page you are looking for.</p>
            <Button url="/" text="Go back to home" />
          </Container>
        </SectionContent>
      </main>
    );
  }
}

export default NotFound;