import React, { Component } from 'react';
import styled from 'styled-components';

import Container from './Container';

class SectionQuote extends Component {
  render() {
    return (
      <section className={`${ this.props.className }`}>
        <Container sizeSmall>
          <blockquote>
            <p><em>{this.props.quote}</em></p>
          </blockquote>
          <p><strong>{this.props.author}</strong>, {this.props.position}</p>
        </Container>
      </section>
    );
  }
}

export default styled(SectionQuote)`
  padding: 40px 0 50px;
  background: var(--accent-color);
  color: var(--text-color--light);
  blockquote {
    margin: 0 0 10px;
  }
  p {
    strong {
      text-transform: uppercase;
    }
  }
`;