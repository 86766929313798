import React, { Component } from 'react';
import styled, {css} from 'styled-components';

class MenuSocial extends Component {
  render() {
    return (
      <ul className={`${ this.props.className + ' grid menu--social'}`}>
        <li className="grid__col-auto">
          <a href="https://www.facebook.com/BrightWingMedia/" target="_blank" rel="noopener noreferrer">
            <svg id="icon--fb" viewBox="0 0 60.734 60.733">
              <path d="M57.378,0.001H3.352C1.502,0.001,0,1.5,0,3.353v54.026c0,1.853,1.502,3.354,3.352,3.354h29.086V37.214h-7.914v-9.167h7.914
              v-6.76c0-7.843,4.789-12.116,11.787-12.116c3.355,0,6.232,0.251,7.071,0.36v8.198l-4.854,0.002c-3.805,0-4.539,1.809-4.539,4.462
              v5.851h9.078l-1.187,9.166h-7.892v23.52h15.475c1.852,0,3.355-1.503,3.355-3.351V3.351C60.731,1.5,59.23,0.001,57.378,0.001z"/>
            </svg>
          </a>
        </li>
        <li className="grid__col-auto">
          <a href="https://twitter.com/brightwing" target="_blank" rel="noopener noreferrer">
            <svg id="icon--twitter" viewBox="0 0 612 612">
              <path d="M612,116.258c-22.525,9.981-46.694,16.75-72.088,19.772c25.929-15.527,45.777-40.155,55.184-69.411
              c-24.322,14.379-51.169,24.82-79.775,30.48c-22.907-24.437-55.49-39.658-91.63-39.658c-69.334,0-125.551,56.217-125.551,125.513
              c0,9.828,1.109,19.427,3.251,28.606C197.065,206.32,104.556,156.337,42.641,80.386c-10.823,18.51-16.98,40.078-16.98,63.101
              c0,43.559,22.181,81.993,55.835,104.479c-20.575-0.688-39.926-6.348-56.867-15.756v1.568c0,60.806,43.291,111.554,100.693,123.104
              c-10.517,2.83-21.607,4.398-33.08,4.398c-8.107,0-15.947-0.803-23.634-2.333c15.985,49.907,62.336,86.199,117.253,87.194
              c-42.947,33.654-97.099,53.655-155.916,53.655c-10.134,0-20.116-0.612-29.944-1.721c55.567,35.681,121.536,56.485,192.438,56.485
              c230.948,0,357.188-191.291,357.188-357.188l-0.421-16.253C573.872,163.526,595.211,141.422,612,116.258z"/>
            </svg>
          </a>
        </li>
        <li className="grid__col-auto">
          <a href="https://vimeo.com/brightwingbooks" target="_blank" rel="noopener noreferrer">
            <svg id="icon--vimeo" viewBox="0 0 508.52 508.52">
              <path d="M413.172,0H95.347C42.684,0,0,42.684,0,95.347v317.825c0,52.664,42.684,95.347,95.347,95.347
                h317.825c52.664,0,95.347-42.684,95.347-95.347V95.347C508.52,42.684,465.836,0,413.172,0z M405.29,185.069
                c-17.067,96.015-112.319,177.378-140.987,195.939c-28.668,18.561-54.793-7.437-64.264-27.142
                c-10.838-22.375-43.351-144.038-51.869-154.113c-8.518-10.075-34.071,10.075-34.071,10.075l-12.395-16.241
                c0,0,51.901-61.944,91.375-69.731c41.858-8.232,41.794,64.296,51.869,104.533c9.757,38.934,16.273,61.181,24.759,61.181
                c8.549,0,24.822-21.676,42.62-54.952c17.862-33.308-0.763-62.739-35.628-41.826C290.683,109.173,422.326,89.055,405.29,185.069z"
                />
              </svg>
          </a>
        </li>
        <li className="grid__col-auto">
          <a href="https://www.instagram.com/brightwingmedia/" target="_blank" rel="noopener noreferrer">
            <svg id="icon--instagram" viewBox="0 0 512.00096 512.00096">
              <path d="m373.40625 0h-234.8125c-76.421875 0-138.59375 62.171875-138.59375 138.59375v234.816406c0 76.417969 62.171875 138.589844 138.59375 138.589844h234.816406c76.417969 0 138.589844-62.171875 138.589844-138.589844v-234.816406c0-76.421875-62.171875-138.59375-138.59375-138.59375zm108.578125 373.410156c0 59.867188-48.707031 108.574219-108.578125 108.574219h-234.8125c-59.871094 0-108.578125-48.707031-108.578125-108.574219v-234.816406c0-59.871094 48.707031-108.578125 108.578125-108.578125h234.816406c59.867188 0 108.574219 48.707031 108.574219 108.578125zm0 0" />
              <path d="m256 116.003906c-77.195312 0-139.996094 62.800782-139.996094 139.996094s62.800782 139.996094 139.996094 139.996094 139.996094-62.800782 139.996094-139.996094-62.800782-139.996094-139.996094-139.996094zm0 249.976563c-60.640625 0-109.980469-49.335938-109.980469-109.980469 0-60.640625 49.339844-109.980469 109.980469-109.980469 60.644531 0 109.980469 49.339844 109.980469 109.980469 0 60.644531-49.335938 109.980469-109.980469 109.980469zm0 0" />
              <path d="m399.34375 66.285156c-22.8125 0-41.367188 18.558594-41.367188 41.367188 0 22.8125 18.554688 41.371094 41.367188 41.371094s41.371094-18.558594 41.371094-41.371094-18.558594-41.367188-41.371094-41.367188zm0 52.71875c-6.257812 0-11.351562-5.09375-11.351562-11.351562 0-6.261719 5.09375-11.351563 11.351562-11.351563 6.261719 0 11.355469 5.089844 11.355469 11.351563 0 6.257812-5.09375 11.351562-11.355469 11.351562zm0 0" />
            </svg>
          </a>
        </li>
        <li className="grid__col-auto">
          <a href="https://www.linkedin.com/in/kaleeg/" target="_blank" rel="noopener noreferrer">
            <svg id="icon--linkedin" viewBox="0 0 430.117 430.118">
              <g>
                <path d="M398.355,0H31.782C14.229,0,0.002,13.793,0.002,30.817v368.471   c0,17.025,14.232,30.83,31.78,30.83h366.573c17.549,0,31.76-13.814,31.76-30.83V30.817C430.115,13.798,415.904,0,398.355,0z    M130.4,360.038H65.413V165.845H130.4V360.038z M97.913,139.315h-0.437c-21.793,0-35.92-14.904-35.92-33.563   c0-19.035,14.542-33.535,36.767-33.535c22.227,0,35.899,14.496,36.331,33.535C134.654,124.415,120.555,139.315,97.913,139.315z    M364.659,360.038h-64.966V256.138c0-26.107-9.413-43.921-32.907-43.921c-17.973,0-28.642,12.018-33.327,23.621   c-1.736,4.144-2.166,9.94-2.166,15.728v108.468h-64.954c0,0,0.85-175.979,0-194.192h64.964v27.531   c8.624-13.229,24.035-32.1,58.534-32.1c42.76,0,74.822,27.739,74.822,87.414V360.038z M230.883,193.99   c0.111-0.182,0.266-0.401,0.42-0.614v0.614H230.883z" />
              </g>
            </svg>
          </a>
        </li>
      </ul>
    );
  }
}

export default styled(MenuSocial)`
  max-width: 275px;
  margin: 0 0 0 auto;
  li {
    margin: 0 14px;
  }
  svg {
    max-width: 30px;
    path {
      fill: var(--text-color--light);
      transition: all .3s;
    }
    &:hover {
      path {
        fill: var(--text-color--accent);
      }
    }
  }
  ${props => props.hoverAnimation && css`
    svg {
      transition: all .3s;
      &:hover {
        margin: 5px 0 0;
      }
    }
  `}
`;