import React, { Component } from 'react';
import styled from 'styled-components';

import Container from './Container';
import SectionHeading from './SectionHeading';

class TextBox extends Component {
  render() {
    return (
      <section className={`${ this.props.className }`}>
        <Container sizeSmall>
          <SectionHeading text={this.props.heading} />
          <p>{this.props.text}</p>
          {this.props.children}
        </Container>
      </section>
    );
  }
}

export default styled(TextBox)`
  text-align: ${props => props.textLeft ? 'left' : 'center'};
  background: ${props => props.bgGrey ? 'var(--background--grey)' : ''};
  .container {
    padding: 60px 0 55px;
  }
  .section__heading {
    margin: 0 0 15px;
  }
  .btn {
    margin: 55px auto  65px;
  }
`;