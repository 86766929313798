import React, { Component } from 'react';

import ContentRow from './components/ContentRow';
import SectionCTA from './components/SectionCTA';
import SectionHero from './components/SectionHero';
import SectionIntro from './components/SectionIntro';

import audioBookLogos from './assets/img/audiobooks/book__logos.png';
import audioBookContent from './assets/img/audiobooks/book__content.jpg';

class Audiobooks extends Component {
  componentDidMount() {
    document.title = "Bright Wing Media | Audiobooks";
  }

  render() {
    return (
      <main className={`${ this.props.className }`}>
        <SectionHero audiobooks heading="Bright Wing Audiobooks" />
        <SectionIntro
          twoColumns
          heading="World-class Recordings"
        >
          <p>It’s no secret that we love the written word here at Bright Wing. Whether digital or analog, we delight in the works of our authors. We’re pleased to offer yet another avenue of access for an author’s written work with the addition of audiobook production. Audiobooks are a fast-growing segment in the digital publishing industry and one which offers a new level of accessibility and vibrancy to the life of the work.</p>
          <p>Audiobook production at Bright Wing mirrors that of our commitment in any of our digital products. We strive to produce works that are representative of the book before us and to honour the craft of the writer. We take an individualized approach when producing an audiobook. We guide the author or publisher through the process of choosing the right voice for the book, editing that will keep the pacing and clarity, producing beautiful audio for enjoyable listening.</p>
        </SectionIntro>
        <ContentRow
          src={audioBookLogos}
          alt="Audiobook - Logos"
          heading="How we make audiobooks"
          text="The production process of our audiobooks begins with a consultation about the vision the author and publisher have for the narration of the book. For some books, narration by the author herself is the best choice, for others, we can offer custom audition reads of the author’s book from a variety of voice actors. Digital recording makes it possible for us to capture the perfect “voice” of the book locally at our Vancouver studio, or remotely, using voice talent anywhere in the world. From here we will edit and master the read with our in-house staff to create a smooth, clean product. We ask our clients to do a listen-through of this edit for accuracy. Additional options for audiobooks include music beds to enhance the listening experience. The finished product is upload-ready for any of the audiobook platforms available; Audible, Overdrive, Downpour, or perhaps consider a presence on the Bright Wing website with your own author platform!"
        />
        <ContentRow
          alignRight
          imgFullSize
          src={audioBookContent}
          alt="Forest Road"
          heading="Where Can People Listen to My Audiobook?"
          text="The finished product is upload-ready for any of the audiobook platforms available; Audible, Overdrive, Downpour, or perhaps consider a presence on the Bright Wing website with your own author platform!"
        />
        <SectionCTA
          text="Interested in an Audiobook? Want to learn more?"
          btn1Text="Get in touch!"
          btn1Url="mailto:aloha@brightwing.ca"
        />
      </main>
    );
  }
}

export default Audiobooks;