import React, { Component } from 'react';
import styled from 'styled-components';

import logo from '../assets/img/logo.svg';

class Logo extends Component {
  render() {
    return (
      <h1 className={this.props.className}>
        <a href="/">
          <img src={logo} alt="Bright Wing Media Logo" />
        </a>
      </h1>
    );
  }
}

export default styled(Logo)`
  width: 285px;
  max-height: 67px;
  @media only screen and (max-width: 500px) {
    width: 220px;
    max-height: 52px;
  }
`;