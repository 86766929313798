import React, { Component } from 'react';
import styled from 'styled-components';

class Container extends Component {
  render() {
    return (
      <div className={`${ this.props.className + ' container'}`}>
        {this.props.children}
      </div>
    );
  }
}

export default styled(Container)`
  width: 85%;
  max-width: ${props => props.sizeSmall ? '840px' : '1200px'};
  margin: 0 auto;
`;