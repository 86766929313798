import React, { Component } from 'react';
import styled from 'styled-components';

import client1 from '../assets/img/clients/logo--art-institute.jpg';
import client2 from '../assets/img/clients/logo--figure.jpg';
import client3 from '../assets/img/clients/logo--mc-foundation.png';
import client4 from '../assets/img/clients/logo--orca.png';
import client5 from '../assets/img/clients/logo--page2.jpg';
import client6 from '../assets/img/clients/logo--reca.png';
import client7 from '../assets/img/clients/logo--stripe.svg';
import client8 from '../assets/img/clients/logo--ubc-press.png';
import client9 from '../assets/img/clients/logo--women-in-film-tv.png';
import client10 from '../assets/img/clients/logo--yo.jpg';

class SectionClients extends Component {
  render() {
    return (
      <section className={`${ this.props.className + ' grid'}`}>
        <figure className="grid__col-md-1 grid__col-sm-2 grid__col-3">
          <img src={client1} alt="Logo " />
        </figure>
        <figure className="grid__col-md-1 grid__col-sm-2 grid__col-3">
          <img src={client2} alt="Logo " />
        </figure>
        <figure className="grid__col-md-1 grid__col-sm-2 grid__col-3">
          <img src={client3} alt="Logo " />
        </figure>
        <figure className="grid__col-md-1 grid__col-sm-3 grid__col-3">
          <img src={client4} alt="Logo " />
        </figure>
        <figure className="grid__col-md-1 grid__col-sm-2 grid__col-3">
          <img src={client5} alt="Logo " />
        </figure>
        <figure className="grid__col-md-1 grid__col-sm-2 grid__col-3">
          <img src={client6} alt="Logo " />
        </figure>
        <figure className="grid__col-md-1 grid__col-sm-2 grid__col-3">
          <img src={client7} alt="Logo " />
        </figure>
        <figure className="grid__col-md-1 grid__col-sm-2 grid__col-3">
          <img src={client8} alt="Logo " />
        </figure>
        <figure className="grid__col-md-1 grid__col-sm-2 grid__col-3">
          <img src={client9} alt="Logo " />
        </figure>
        <figure className="grid__col-md-1 grid__col-sm-2 grid__col-3">
          <img src={client10} alt="Logo " />
        </figure>
      </section>
    );
  }
}

export default styled(SectionClients)`
  justify-content: space-around;
  padding: 45px 0 15px;
  figure {
    align-items: center;
    justify-content: center;
    margin: 0 0 35px;
    img {
      width: 100%;
      max-width: 150px;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      &.grid__col-sm-3 {
        width: 17%;
      }
    }
    @media only screen and (max-width: 767px) {
      padding: 0 10px;
    }
    @media only screen and (max-width: 550px) {
      width: 30%;
    }
    @media only screen and (max-width: 400px) {
      width: 45%;
    }
  }
`;