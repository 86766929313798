import React, { Component } from 'react';
import styled, {css} from 'styled-components';

import aboutHero from '../assets/img/about/hero.jpg';
import aboutIcon from '../assets/icons/icon--about.svg';
import audioBooksHero from '../assets/img/audiobooks/hero.jpg';
import audioBooksIcon from '../assets/icons/icon--audio-books.svg';
import connectHero from '../assets/img/connect/hero.jpg';
import ebooksHero from '../assets/img/ebooks/hero.jpg';
import ebooksIcon from '../assets/icons/icon--ebooks.svg';
import filmIcon from '../assets/icons/icon--film.svg';
import notFoundHero from '../assets/img/not-found__hero.jpg';
import systemsHero from '../assets/img/systems/hero.jpg';
import systemsIcon from '../assets/icons/icon--systems.svg';
import webPlatformsHero from '../assets/img/web/hero.jpg';
import webPlatformsIcon from '../assets/icons/icon--web-platforms.svg';

const Heading = styled.h2`
  font-size: 30px;
  margin: 0 0 10px;
  &:before {
    display: block;
    width: 100%;
    height: 90px;
    content: '';
    margin: 0 0 25px;
    background-position: center center;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }
  @media only screen and (max-width: 767px) {
    font-size: 25px;
  }
`;

const SubHeading = styled.h3`
  font-size: 18px;
`;

class SectionHero extends Component {
  render() {
    return (
      <section className={`${ this.props.className }`}>
        {this.props.children}
        <Heading>{this.props.heading}</Heading>
        <SubHeading>{this.props.subheading}</SubHeading>
      </section>
    );
  }
}

export default styled(SectionHero)`
  display: flex;
  height: 496px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 96px 0 0;
  background-position: center center;
  background-repeat: no-repeat;
  h2,
  h3 {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--brand-color);
  }
  ${props => props.about && css`
    background-image: url(${aboutHero});
    background-size: cover;
    h2 {
      color: var(--text-color--light);
      &:before {
        background-image: url(${aboutIcon});
        background-size: 100% auto;
      }
    }
  `}
  ${props => props.audiobooks && css`
    background-image: url(${audioBooksHero});
    background-size: cover;
    h2:before {
      background-image: url(${audioBooksIcon});
    }
  `}
  ${props => props.connect && css`
    background-image: url(${connectHero});
    background-size: cover;
    h2 {
      color: var(--text-color--light);
      &:before {
        display: none;
      }
    }
  `}
  ${props => props.ebooks && css`
    background-image: url(${ebooksHero});
    background-size: cover;
    h2 {
      color: var(--text-color--light);
      &:before {
        background-image: url(${ebooksIcon});
        filter: brightness(8);
      }
    }
  `}
  ${props => props.film && css`
    position: relative;
    video {
      position: absolute;
      left: 0; 
      bottom: 0;
      min-width: 100%; 
      min-height: 100%;
      width: auto; 
      height: auto; 
      z-index: -100;
      @media only screen and (min-width: 1250px) {
        bottom: -300px;
      }
    }
    h2 {
      position: relative;
      color: var(--text-color--light);
      &:before {
        background-image: url(${filmIcon});
        filter: brightness(8);
      }
    }
  `}
  ${props => props.notFound && css`
    background-image: url(${notFoundHero});
    background-size: cover;
    h2 {
      color: var(--text-color--light);
      &:before {
        display: none;
      }
    }
  `}
  ${props => props.systems && css`
    background-image: url(${systemsHero});
    background-size: cover;
    h2:before {
      background-image: url(${systemsIcon});
    }
  `}
  ${props => props.webPlatforms && css`
    background-image: url(${webPlatformsHero});
    background-size: cover;
    h2:before {
      background-image: url(${webPlatformsIcon});
    }
    @media only screen and (max-width: 767px) {
      background-position: center left;
    }
  `}
  @media only screen and (max-width: 767px) {
    text-align: center;
    padding: 96px 7.5% 0;
  }
`;