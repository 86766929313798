import React, { Component } from 'react';
import styled from 'styled-components';

import Container from './components/Container';
import SectionCTA from './components/SectionCTA';
import SectionHeading from './components/SectionHeading';
import SectionHero from './components/SectionHero';
import SectionIntro from './components/SectionIntro';
import SectionQuote from './components/SectionQuote';
import SectionSubheading from './components/SectionSubheading';
import SectionValues from './components/SectionValues';

import previewSite from './assets/img/ebooks/preview-site.png';
import previewPlan from './assets/img/ebooks/preview-plan.jpg';
import projectsEbook1 from './assets/img/ebooks/book--1.jpg';
import projectsEbook2 from './assets/img/ebooks/book--2.jpg';
import projectsEbook3 from './assets/img/ebooks/book--3.jpg';
import projectsEbook4 from './assets/img/ebooks/book--4.jpg';
import projectsEbook5 from './assets/img/ebooks/book--5.jpg';
import projectsEbook6 from './assets/img/ebooks/book--6.jpg';

const SectionOtherEbooks = styled.section`
  background: var(--background--grey);
  padding: 45px 0;
  .section__subheading {
    margin: 0 0 15px;
  }
  p {
    width: 90%;
  }
  @media only screen and (max-width: 767px) {
    article:not(:last-of-type) {
      margin: 0 0 45px;
    }
  }
`;

const SectionPastProjects = styled.section`
  @media only screen and (max-width: 767px) {
    ul li {
      align-items: center;
      margin: 0 0 35px;
    }
  }
`;

class Ebooks extends Component {
  componentDidMount() {
    document.title = "Bright Wing Media | Ebooks";
  }

  render() {
    return (
      <main className={`${ this.props.className }`}>
        <SectionHero ebooks heading="Bright Wing Ebooks" />
        <SectionIntro
          twoColumns
          heading="Congratulations are in order!"
        >
          <p>Most likely, you have clicked through to this page because you have finished a book! If you’re a publisher, then you’ve taken a big step accepting a manuscript, and have invested time, energy, and money into producing it for publication. If you are an independent author, you have accomplished a major feat – you wrote a book! There is no denying the hard work and dedication it has taken you, whether publisher or author, to get to this point… so, congratulations!</p>
          <p>At Bright Wing, we put craftsmanship into every project. Our ebooks are hand-coded. We are meticulous about typesetting, the document structure, accessibility, and the accuracy of our conversions. Our clients come first, and we are proud of the customer relationships we’ve built. At Bright Wing Media, we work hard to ensure the client’s vision for their book is achieved and their deadlines are met.</p>
        </SectionIntro>
        <SectionValues
          img1={previewSite}
          heading1="Beautiful on all devices"
          text1="Our ebooks will render properly on all devices, from desktops to tablets and dedicated ereaders. We will work closely with the author or editor to ensure that the books are error free before distribution to the stores. If there is a problem discovered at any time after publication which exists as a result of our conversion, we will fix the error free of charge."
          img2={previewPlan}
          heading2="Clean and Industry-standard"
          text2="The Bright Wing team is dedicated to producing books which have clean and well-formatted code. We use current EPUB standards for accessibility, and we build backward compatibility into all our books. We can guarantee that your books will be produced with the highest standards in digital production."
        />
        <SectionQuote
          quote="I am writing to thank the Bright Wing Media team for the amazing ebook conversion they performed for me. I was very nervous about the process as this was my first foray into the world of publication. As a writer of fiction venturing into the world of ebooks, this was a scenario fraught with danger. Enter the Bright Wing team, who, led by Kaleeg, made the process easy and manageable. Bright Wing’s team were supportive when the pressures of editing, cover design, and placing illustrative work throughout the text began to feel a little overwhelming. My book contained around thirty illustrations. The Bright Wing team handled this element of the conversion with no trouble whatsoever. I also would like to highlight the fact that the team dealt brilliantly with the fact that each writer has a special connection with their own work and that the need for exactitude regarding all design aspects can be a little demanding. The Bright Wing team are patient, knowledgeable and highly proficient. I would recommend them highly to anyone wanting a painless interaction and a professional finish."
          author="Daniel Nuun"
          position="Author of The Huffy Touch"
        />
        <Container className="container__heading">
          <SectionHeading extraSpacing text="Other kinds of ebooks we make" />
        </Container>
        <SectionOtherEbooks>
          <Container className="grid">
            <article className="grid__col-sm-6 grid__col-12">
              <SectionSubheading text="Enhanced Ebooks"/>
              <p>Enhanced ebooks are an emerging publication medium fuelled by the advent of the ePub 3 code standards. Enhanced ebooks usually have sound, video, and interactive elements built into the book, allowing readers to read, hear voice or music tracks embedded right in the book, or have interactive videos. For instance, if you have written a book on small engine mechanics, then you might want to include a short video as an embedded file in your text flow so that you can show first hand what part of the engine you are referring to. The possibilities are endless, and Bright Wing Media is very interested in exploring those possibilities with you. Please contact us for details.</p>
            </article>
            <article className="grid__col-sm-6 grid__col-12">
              <SectionSubheading text="Fixed Layout Books" />
              <p>We can also produce fixed-layout ebooks. A fixed-layout ebook will render your book in an ereader without reflowing the content. These books are especially useful if you are publishing a children’s book or any book with many illustrations or an extremely complex layout. Fixed-layout ebooks are ideal for iPads and later generation ereaders because they can also have built in interactive features. A fixed-layout ebook involves careful document preparation, extensive mark-up and testing, and careful layout and image sampling. If your fixed-layout ebook will have audio, narration, or video, then the process is that much more extensive. As such, a fixed-layout book can be more expensive to produce than a standard, re-flowable book.</p>
            </article>
          </Container>
        </SectionOtherEbooks>
        <Container className="container__heading">
          <SectionHeading extraSpacing text="Some of our past projects" />
        </Container>
        <SectionPastProjects>
          <ul className="grid">
            <li className="grid__col-md-auto grid__col-sm-4 grid__col-12">
              <img src={projectsEbook1} alt="Book Cover" />
            </li>
            <li className="grid__col-md-auto grid__col-sm-4 grid__col-12">
              <img src={projectsEbook2} alt="Book Cover" />
            </li>
            <li className="grid__col-md-auto grid__col-sm-4 grid__col-12">
              <img src={projectsEbook3} alt="Book Cover" />
            </li>
            <li className="grid__col-md-auto grid__col-sm-4 grid__col-12">
              <img src={projectsEbook4} alt="Book Cover" />
            </li>
            <li className="grid__col-md-auto grid__col-sm-4 grid__col-12">
              <img src={projectsEbook5} alt="Book Cover" />
            </li>
            <li className="grid__col-md-auto grid__col-sm-4 grid__col-12">
              <img src={projectsEbook6} alt="Book Cover" />
            </li>
          </ul>
        </SectionPastProjects>
        <SectionCTA
          text="Want to see some beautiful books?"
          btn1Text="Check out our bookstore!"
          btn1Url="https://brightwingbooks.booksites.ca/"
          btn1Target="_blank"
        />
      </main>
    );
  }
}

export default Ebooks;