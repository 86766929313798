import React, { Component } from 'react';
import styled, {css} from 'styled-components';

import Button from './Button';

const Container = styled.div`
  width: 85%;
  max-width: 800px;
  justify-content: space-around;
  align-items: center;
  margin: 75px auto 60px;
  p {
    color: var(--text-color--dark);
  }
  @media only screen and (max-width: 767px) {
    p {
      width: 100%;
      text-align: center;
      margin: 0 0 35px;
    }
  }
`;

class SectionCTA extends Component {
  render() {
    return (
      <section className={`${ this.props.className}`}>
        <Container className="grid">
          <p>{this.props.text}</p>
          <Button text={this.props.btn1Text} url={this.props.btn1Url} target={this.props.btn1Target} />
          <Button className="btn--cta2" text={this.props.btn2Text} url={this.props.btn2Url} target={this.props.btn2Target} />
        </Container>
      </section>
    );
  }
}

export default styled(SectionCTA)`
  .btn--cta2 {
    display: ${props => props.displayCTA2 ? 'block' : 'none'};
  }
  ${props => props.displayCTA2 && css`
    @media only screen and (max-width: 991px) {
      p {
        width: 100%;
        text-align: center;
        margin: 0 0 35px;
      }
    }
    @media only screen and (max-width: 600px) {
      div {
        flex-direction: column;
      }
      .btn:not(:last-of-type) {
        margin: 0 0 35px;
      }
    }
  `}
`;