import React, { Component } from 'react';
import styled from 'styled-components';

class SectionSubheading extends Component {
  render() {
    return (
      <h3 className={`${ this.props.className + ' section__subheading'}`}>{this.props.text}</h3>
    );
  }
}

export default styled(SectionSubheading)`
  font-size: 18px;
  line-height: 1.6;
  font-weight: var(--fw--black);
  letter-spacing: 1px;
  text-transform: ${props => props.uppercase ? 'uppercase' : ''};
  color: var(--text-color--dark);
`;