import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import Button from './components/Button';
import Container from './components/Container';
import ContentRow from './components/ContentRow';
import ImageGrid from './components/ImageGrid';
import SectionClients from './components/SectionClients';
import SectionHeading from './components/SectionHeading';
import ServicesNav from './components/ServicesNav';

import BWLettering from './assets/img/home/bright-wing-lettering.png';
import heroBg from './assets/img/home/hero__bg.jpg';
import quoteBg from './assets/img/home/hero__quote.jpg';
import serviceProject1 from './assets/img/home/services__project-1.jpg';
import serviceProject2 from './assets/img/home/services__project-2.jpg';
import serviceProject3 from './assets/img/home/services__project-3.jpg';
import serviceProject4 from './assets/img/home/services__project-4.jpg';
import serviceProject5 from './assets/img/home/services__project-5.jpg';
import serviceProject6 from './assets/img/home/services__project-6.jpg';
import teamImg from './assets/img/home/home__team.jpg';

const SectionHero = styled.section`
  display: flex;
  align-items: flex-end;
  min-height: 750px;
  padding: 8.5% 0;
  background: top left url(${heroBg}) no-repeat;
  background-size: cover;
  h2 {
    max-width: 490px;
    font-size: 30px;
    line-height: 1.5;
    color: var(--text-color--light);
    span {
      display: block;
      font-size: 18px;
      line-height: 2.5;
      font-weight: var(--fw--black);
      text-transform: uppercase;
    }
  }
  @media only screen and (max-width: 991px) {
    align-items: center;
  }
`;

const SectionIntro = styled.section`
  padding: 65px 0 100px;
  background: var(--brand-color);
  color: var(--text-color--light);
  .intro__logo {
    align-items: flex-end;
    padding: 30px 35px 0 0;
    &:before,
    &:after {
      position: absolute;
      content: '';
      background: var(--text-color--accent);
    }
    &:before {
      top: 0;
      left: 0;
      width: 55%;
      height: 1px;
    }
    .section__heading {
      letter-spacing: 1px;
      margin: 0 0 30px;
    }
  }
  .intro__text {
    padding: 26px 45px 0;
    p:not(:last-child) {
      margin: 0 0 25px;
    }
  }
  @media only screen and (min-width: 992px) {
    .intro__logo {
      &:after {
        bottom: 0;
        right: 0;
        width: 1px;
        height: 95%;
      }
      .section__heading {
        text-align: right;
      }
    }
    .intro__text p {
      max-width: 445px;
    }
  }
  @media only screen and (max-width: 991px) {
    .intro__logo {
      .section__heading {
        margin: 0 45px;
      }
      img {
        display: none;
      }
    }
  }
`;

const SectionTestimonial = styled.section`
  padding: 110px 0 95px;
  background: fixed top left url(${quoteBg}) no-repeat;
  background-size: cover;
  color: var(--text-color--light);
  blockquote {
    max-width: 570px;
    font-size: 15px;
    line-height: 2;
    margin: 0 0 15px;
  }
  p {
    span {
      text-transform: uppercase;
      color: var(--text-color--accent);
    }
  }
  @media only screen and (min-width: 768px) {
    blockquote {
      width: 90%;
    }
  }
  @media only screen and (max-width: 767px) {
    article:not(:last-of-type) {
      margin: 0 0 55px;
    }
  }
`;

class Home extends Component {
  render() {
    return (
      <main className={`${ this.props.className }`}>
        <SectionHero>
          <Container>
            <h2><span>We are a digital media house</span>Specializing in ebooks, publishing systems, author platforms, short films, and audiobooks.</h2>
          </Container>
        </SectionHero>
        <SectionIntro className="grid">
          <article className="grid__col-md-6 grid__col-12 intro__logo">
            <SectionHeading highlighted text="Beautiful digital publishing" />
            <img src={BWLettering} alt="Bright Wing Media Logo" />
          </article>
          <article className="grid__col-md-6 grid__col-12 intro__text">
            <p>We’re glad you’re here! Bright Wing Media is a full-service digital publishing company based in Vancouver, BC, Canada. We work for authors, publishers, and organizations around the world and we strive for excellence in ebooks, web platforms, publishing systems, audiobooks, and film. Most of all, we care about good customer relationships and beautiful digital products.</p>
            <p>We are a growing company with a big heart and we really love what we do. Our team includes designers, developers, film crew and sound engineers and we want to bring our best to your project. We hand-craft our ebooks, apply integrity to our design and layout work, and produce heart-felt film. Give us a call! Let’s chat about what you dream of doing in the publishing world.</p>
          </article>
        </SectionIntro>
        <section>
          <SectionHeading bgGrey text="Our Services" />
          <ServicesNav />
          <ImageGrid home>
            <li className="grid__col-md-4 grid__col-sm-6 grid__col-12">
              <NavLink to="/ebooks">
                <img src={serviceProject1} alt="Ebooks Project Preview" />
                <div>
                  <h4>Ebooks</h4>
                </div>
              </NavLink>
            </li>
            <li className="grid__col-md-4 grid__col-sm-6 grid__col-12">
              <NavLink to="/audiobooks">
                <img src={serviceProject2} alt="Audiobooks Project Preview" />
                <div>
                  <h4>Audiobooks</h4>
                </div>
              </NavLink>
            </li>
            <li className="grid__col-md-4 grid__col-sm-6 grid__col-12">
              <NavLink to="/film">
                <img src={serviceProject3} alt="Film Project Preview" />
                <div>
                  <h4>Film</h4>
                </div>
              </NavLink>
            </li>
            <li className="grid__col-md-4 grid__col-sm-6 grid__col-12">
              <NavLink to="/web-platforms">
                <img src={serviceProject4} alt="Web Platforms Project Preview" />
                <div>
                  <h4>Web Platforms</h4>
                </div>
              </NavLink>
            </li>
            <li className="grid__col-md-4 grid__col-sm-6 grid__col-12">
              <NavLink to="/publishing-systems">
                <img src={serviceProject5} alt="Publishing Systems Project Preview" />
                <div>
                  <h4>Publishing Systems</h4>
                </div>
              </NavLink>
            </li>
            <li className="grid__col-md-4 grid__col-sm-6 grid__col-12 service__bookstore">
              <a href="https://brightwingbooks.booksites.ca/" target="_blank" rel="noopener noreferrer">
                <img src={serviceProject6} alt="Booktore Preview" />
              </a>
            </li>
          </ImageGrid>
        </section>
        <ContentRow
          alignRight
          src={teamImg}
          alt="Team"
          heading=" The Bright Wing Team"
          text="Whether it is making an ebook or a book trailer, Bright Wing Media wants you to know that you have an advocate in us! You are not alone! We have the experience, the tools, and knowledge to help you through every stage of the publishing or video production process. We stand by you, we cheer you on, and there is always a real person to answer your call. Our years of professional experience, working with every part in that process (designers, editors, artists, co-authors, actors, musicians, web developers etc.), mean that we know how to get your project done right and done on time."
        >
          <Button marginTop text="Learn more" url="/about-us" />
        </ContentRow>
        <SectionTestimonial>
          <Container className="grid">
            <article className="grid__col-sm-6 grid__col-12">
              <blockquote><em>At Bright Wing Media you’ll find a team of dedicated professionals who excel at crafting beautiful ebooks and book trailers. They’re not just excellent technicians at the leading edge of digital publishing; they’re also passionate lovers of books and ardent supporters of authors and publishers. They care deeply about their work, they’re consummate professionals in everything they do, and they’re a joy to work with on top of it all.</em></blockquote>
              <p><span>Jesse Finklestein</span></p>
              <p>Principal | Page Two Strategies</p>
            </article>
            <article className="grid__col-sm-6 grid__col-12">
              <blockquote><em>After seeing what Kaleeg and his team are capable of, I fired myself from doing ebook conversions and hired Bright Wing instead. Their style sheets are beautiful and their code jumps through every hoop of digital distribution. Kaleeg and his team are accommodating, masterful and lovely people to work with. They’ve saved me countless hours of toil and troubleshooting, and have made the digital publishing side of my job a piece of cake.</em></blockquote>
              <p><span>Lara Smith</span></p>
              <p>Managing Editor | Figure 1 Publishers</p>
            </article>
          </Container>
        </SectionTestimonial>
        <SectionClients />
      </main>
    );
  }
}

export default Home;