import React, { Component } from 'react';

class MenuBookstore extends Component {
  render() {
    return (
      <div className={`${ this.props.className}`}>
        <h4>
          <a href="https://brightwingbooks.booksites.ca/" target="_blank" rel="noopener noreferrer">
            Bookstore
          </a>
        </h4>
        <li>
          <a href="https://brightwingbooks.booksites.ca/the-revolt-of-the-public/" target="_blank" rel="noopener noreferrer">
            The Revolt of the Public
          </a>
        </li>
        <li>
          <a href="https://brightwingbooks.booksites.ca/the-algorithmic-leader/" target="_blank" rel="noopener noreferrer">
            The Algorithmic Leader
          </a>
        </li>
        <li>
          <a href="https://brightwingbooks.booksites.ca/dust-in-my-pack/" target="_blank" rel="noopener noreferrer">
            Dust in my pack
          </a>
        </li>
        <li>
          <a href="https://booksites.ca/books/high-growth-handbook" target="_blank" rel="noopener noreferrer">
            High growth handbook
          </a>
        </li>
        <li>
          <a href="https://brightwingbooks.booksites.ca/alex-colville-art-canada-institute/" target="_blank" rel="noopener noreferrer">
            Alex Colville | Art Canada Institute
          </a>
        </li>
      </div>
    );
  }
}

export default MenuBookstore;