import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import Container from './components/Container';
import ImageGrid from './components/ImageGrid';
import SectionCTA from './components/SectionCTA';
import SectionHero from './components/SectionHero';
import SectionIntro from './components/SectionIntro';
import SectionQuote from './components/SectionQuote';
import SectionSubheading from './components/SectionSubheading';

import slack from './assets/img/about/slack.jpg';
import teamMemberAlicia from './assets/img/about/team--alicia.jpg';
import teamMemberAngela from './assets/img/about/team--angela.jpg';
import teamMemberAugustus from './assets/img/about/team--augustus.jpg';
import teamMemberBryan from './assets/img/about/team--bryan.jpg';
import teamMemberBronwyn from './assets/img/about/team--bronwyn.jpg';
import teamMemberKaleeg from './assets/img/about/team--kaleeg.jpg';
import teamMemberLouis from './assets/img/about/team--louis.jpg';
import teamMemberMarty from './assets/img/about/team--marty.jpg';
import teamMemberMelanie from './assets/img/about/team--melanie.jpg';
import teamMemberRachel from './assets/img/about/team--rachel.jpg';
import teamMemberRiley from './assets/img/about/team--riley.jpg';
import teamMemberSilouan from './assets/img/about/team--silouan.jpg';

const SectionSlack = styled.section`
  padding: 45px 0;
  .section__subheading {
    margin: 0 0 15px;
  }
  p {
    width: 90%;
    &:not(:last-child) {
      margin: 0 0 15px;
    }
  }
  a {
    color: var(--text-color--accent);
    border-bottom: 1px solid transparent;
    transition: border .5s;
    &:hover {
      border-bottom: 1px solid var(--text-color--accent);
    }
  }
  @media only screen and (max-width: 767px) {
    article:not(:last-of-type) {
      margin: 0 0 45px;
    }
  }
`;

class About extends Component {
  componentDidMount() {
    document.title = "Bright Wing Media | About Us";
  }

  render() {
    return (
      <main className={`${ this.props.className }`}>
        <SectionHero about heading="About us" />
        <SectionIntro
          heading="Our Team"
        >
          <p>Whether it is making an ebook or a book trailer, Bright Wing Media wants you to know that you have an advocate in us! You are not alone! We have the experience, the tools, and knowledge to help you through every stage of the publishing or video production process. We stand by you, we cheer you on, and there is always a real person to answer your call.</p>
          <p>Our years of professional experience, working with every part in that process (designers, editors, artists, co-authors, actors, musicians, web developers etc.), mean that we know how to get your project done right and done on time.</p>
        </SectionIntro>
        <section>
          <ImageGrid about>
            <li className="grid__col-md-4 grid__col-sm-6 grid__col-12">
              <img src={teamMemberKaleeg} alt="Profile Kaleeg" />
              <div>
                <h4>
                  <span>Kaleeg</span>
                  <span><small>- Founder -</small></span>
                </h4>
              </div>
            </li>
            <li className="grid__col-md-4 grid__col-sm-6 grid__col-12">
              <img src={teamMemberAngela} alt="Profile Angela" />
              <div>
                <h4>Angela</h4>
              </div>
            </li>
            <li className="grid__col-md-4 grid__col-sm-6 grid__col-12">
              <img src={teamMemberBronwyn} alt="Profile Bronwyn" />
              <div>
                <h4>Bronwyn</h4>
              </div>
            </li>
            <li className="grid__col-md-4 grid__col-sm-6 grid__col-12">
              <img src={teamMemberAlicia} alt="Profile Alicia" />
              <div>
                <h4>Alicia</h4>
              </div>
            </li>
            <li className="grid__col-md-4 grid__col-sm-6 grid__col-12">
              <img src={teamMemberMelanie} alt="Profile Melanie" />
              <div>
                <h4>Melanie</h4>
              </div>
            </li>
            <li className="grid__col-md-4 grid__col-sm-6 grid__col-12">
              <img src={teamMemberRachel} alt="Profile Rachel" />
              <div>
                <h4>Rachel</h4>
              </div>
            </li>
            <li className="grid__col-md-4 grid__col-sm-6 grid__col-12">
              <img src={teamMemberRiley} alt="Profile Riley" />
              <div>
                <h4>Riley</h4>
              </div>
            </li>
            <li className="grid__col-md-4 grid__col-sm-6 grid__col-12">
              <img src={teamMemberLouis} alt="Profile Louis" />
              <div>
                <h4>Louis</h4>
              </div>
            </li>
            <li className="grid__col-md-4 grid__col-sm-6 grid__col-12">
              <img src={teamMemberSilouan} alt="Profile Silouan" />
              <div>
                <h4>Silouan</h4>
              </div>
            </li>
            <li className="grid__col-md-4 grid__col-sm-6 grid__col-12">
              <img src={teamMemberBryan} alt="Profile Bryan" />
              <div>
                <h4>Bryan</h4>
              </div>
            </li>
            <li className="grid__col-md-4 grid__col-sm-6 grid__col-12">
              <img src={teamMemberMarty} alt="Profile Marty" />
              <div>
                <h4>Marty</h4>
              </div>
            </li>
            <li className="grid__col-md-4 grid__col-sm-6 grid__col-12">
              <img src={teamMemberAugustus} alt="Profile Augustus" />
              <div>
                <h4>Augustus</h4>
              </div>
            </li>
            <li className="grid__col-12 team--join">
              <a href="mailto:aloha[at]brightwing.ca?subject=Want to join the Bright wing team?">
                <h4>Want to join the Bright Wing Team?</h4>
                <p>Click here and send us an email!</p>
              </a>
            </li>
          </ImageGrid>
        </section>
        <SectionSlack>
          <Container className="grid">
            <article className="grid__col-sm-6 grid__col-12">
              <SectionSubheading text="Slack"/>
              <p>Bright Wing Media uses <a href="https://slack.com/" target="_blank" rel="noopener noreferrer">#Slack</a> as our content management system and client support area. <a href="https://slack.com/" target="_blank" rel="noopener noreferrer">Slack</a> is ideal for our team and we think you will like it too. We are able to communicate directly, in real time if need be, and to transfer files back and forth. In addition, the entire exchange is archived and files can be retrieved at any time.</p>
              <p>If you are a new client or want to be one, you can request access to your private <a href="https://slack.com/" target="_blank" rel="noopener noreferrer">Slack</a> channel by providing your name, email, and project title <NavLink to="/connect">in this form</NavLink>. We will set you up with a private workspace with us. Questions? Call or email. Always to good to hear from you.</p>
            </article>
            <article className="grid__col-sm-6 grid__col-12">
              <img src={slack} alt="Preview Slack" />
            </article>
          </Container>
        </SectionSlack>
        <SectionQuote
          quote="Bright Wing Media made my ebook project come alive.  I am a complete newbie to the format, and while I did have much to learn about the genre, the folks at Bright Wing Media made it a painless experience.  Kaleeg and his team are delightful, and thanks to their knowledgeable and efficient service, I have a product that I will share with pride."
          author="Jemma Helene"
          position="Author - French Lessons blog"
        />
        <SectionCTA
          text="Interested in working with us? Want to learn more?"
          btn1Text="Get in touch!"
          btn1Url="mailto:aloha@brightwing.ca"
        />
      </main>
    );
  }
}

export default About;