import React, { Component } from 'react';
import styled from 'styled-components';

class ButtonToggleMenu extends Component {
  render() {
    return (
      <button
        className={this.props.className}
        onClick={this.props.onClick}
      >
        <div></div>
      </button>
    );
  }
}

export default styled(ButtonToggleMenu)`
  position: relative;
  margin: 0 0 0 auto;
  background: none;
  &:hover {
    cursor: pointer;
  }
  div {
    position: relative;
    display: flex;
    width: 27px;
    height: 4px;
    align-self: center;
    background: var(--brand-color);
    border-radius: 10px;
    transition: background .1s;
    transition-delay: .2s;
    &:before,
    &:after {
      position: absolute;
      width: 100%;
      height: 4px;
      content: '';
      background: inherit;
      border-radius: inherit;
      transition: all .3s;
    }
    &:before {
      top: -9px;
    }
    &:after {
      top: 9px;
    }
  }
  &.menu--show {
    div {
      background: none;
      transition-delay: 0s;
      &:before,
      &:after {
        top: 0;
        background: var(--brand-color);
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
`;