import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';

import ButtonToggleMenu from './ButtonToggleMenu';
import HeaderMenu from './HeaderMenu';
import Logo from './Logo';

const fadeIn = keyframes`
  from {
    background: transparent;
  }

  to {
    background: #fff;
  }
`;

const HeaderContainer = styled.div`
  z-index: 1;
  padding: 15px 5%;
  animation: ${fadeIn} .75s 3s linear forwards;
`;

class Header extends Component {
  state = {
    showMenu: false,
  }

  constructor(props) {
    super(props);

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    if(this.state.showMenu){
      this.setState({'showMenu': false});
    } else{
      this.setState({'showMenu': true});
    }
  }

  render() {
    return (
      <header className={`${ this.props.className}`}>
        <HeaderContainer className="grid">
          <Logo className="grid__col-7" />
          <ButtonToggleMenu
            className={this.state.showMenu ? ' menu--show' : ''}
            onClick={this.toggleMenu}
          />
        </HeaderContainer>
        <HeaderMenu
          className={this.state.showMenu ? ' menu--show' : ''}
          onClick={this.toggleMenu}
        />
      </header>
    );
  }
}

export default styled(Header)`
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
`;