import React, { Component } from 'react';
import styled from 'styled-components';

import Container from './Container';
import SectionSubheading from './SectionSubheading';

class SectionValues extends Component {
  render() {
    return (
      <section className={`${ this.props.className}`}>
        <Container className="grid">
            <article className="grid__col-md-6 grid__col-12">
              <figure>
                <img src={this.props.img1} alt="" />
              </figure>
              <SectionSubheading text={this.props.heading1} />
              <p>{this.props.text1}</p>
            </article>
            <article className="grid__col-md-6 grid__col-12">
              <figure>
                <img src={this.props.img2} alt="" />
              </figure>
              <SectionSubheading text={this.props.heading2} />
              <p>{this.props.text2}</p>
            </article>
          </Container>
      </section>
    );
  }
}

export default styled(SectionValues)`
  text-align: left;
  padding: 50px 0 60px;
  figure {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .section__subheading {
    margin: 30px 0;
  }
  @media only screen and (max-width: 991px) {
    article:not(:last-of-type) {
      margin: 0 0 55px;
    }
  }
  @media only screen and (min-width: 768px) {
    article {
      padding: 0 45px;
    }
  }
`;