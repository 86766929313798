import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

class ServicesNav extends Component {
  render() {
    return (
      <ul className={`${ this.props.className + ' grid'}`}>
        <li className="grid__col-lg-auto grid__col-sm-4 grid__col-6">
          <NavLink to="/ebooks">
            <svg id="icon--ebooks" viewBox="0 0 99.121 78.885">
              <g transform="translate(-365.25 -1308.228)">
                <g transform="translate(354.475 1287.03)">
                  <path d="M19,26V22s30.666-1,41.333,8.332C70.666,21,101.667,22,101.667,22v4"/>
                </g>
                <path d="M101.668,25.678V84a115.011,115.011,0,0,0-41.333,9.333h0A115.011,115.011,0,0,0,19,84V25.678c-3.362-.066-7.477,0-7.477,0V90.7c26.039.687,44.794,8.627,44.806,8.632h8.008c.013,0,18.768-7.945,44.807-8.632V25.674S105.029,25.611,101.668,25.678Z" transform="translate(354.475 1287.03)"/>
                <line y1="64.334" transform="translate(412.704 1315.57)"/>
                <line y2="64.334" transform="translate(416.996 1315.57)"/>
              </g>
            </svg>
            <h4>Ebooks</h4>
          </NavLink>
        </li>
        <li className="grid__col-lg-auto grid__col-sm-4 grid__col-6">
          <NavLink to="/audiobooks">
            <svg id="icon--audiobooks" viewBox="0 0 71.886 68.645">
              <g transform="translate(-586.231 -1308.25)">
                <path d="M90.606,72c-9.872-.439-13.87,2.3-14.55,2.632-4.033,1.948-5.74,5.484-4.509,8.417,1.439,3.427,5.724,5.113,10.749,3.707,0,0,7.791-2.081,7.791-10.174l.173-4.687V25.383a2.259,2.259,0,0,0-2.42-2.05l-46.6,7.9a2.258,2.258,0,0,0-2.419,2.049V79.795c0,8.093-7.791,10.173-7.791,10.173-5.026,1.406-9.31-.28-10.749-3.706-1.231-2.933.475-6.469,4.51-8.417.679-.328,4.677-3.071,14.549-2.633" transform="translate(567.107 1285.667)"/>
                <line y1="9.068" x2="51.607" transform="translate(605.414 1323.48)"/>
              </g>
            </svg>
            <h4>Audiobooks</h4>
          </NavLink>
        </li>
        <li className="grid__col-lg-auto grid__col-sm-4 grid__col-6">
          <NavLink to="/film">
            <svg id="icon--film" viewBox="0 0 83 83">
              <g transform="translate(-1260.25 -1296.25)">
                <circle cx="33.663" cy="33.663" r="33.663" transform="translate(1268.087 1304.086)"/>
                <circle cx="40.75" cy="40.75" r="40.75" transform="translate(1261 1297)"/>
                <path d="M52.609,79.071a2.1,2.1,0,0,1-2.231-2.232V44.983c0-1.233.478-2.454,2.231-2.232s24.578,15.507,25.23,16.832a2.287,2.287,0,0,1,0,2.064C77.235,62.995,53.955,78.96,52.609,79.071Z" transform="translate(1241.333 1278.166)"/>
              </g>
            </svg>
            <h4>Film</h4>
          </NavLink>
        </li>
        <li className="grid__col-lg-auto grid__col-sm-4 grid__col-6">
          <NavLink to="/web-platforms">
            <svg id="icon--web-platforms" viewBox="0 0 118.5 92.99">
              <g transform="translate(-780.25 -1296.25)">
                <path d="M110.667,76.2a1.8,1.8,0,0,1-1.8,1.8h-97.4a1.8,1.8,0,0,1-1.8-1.8V23.8a1.8,1.8,0,0,1,1.8-1.8h97.4a1.8,1.8,0,0,1,1.8,1.8Z" transform="translate(779.333 1283)"/>
                <path d="M118.667,83.686A2.208,2.208,0,0,1,116.582,86H3.752a2.208,2.208,0,0,1-2.085-2.314V16.314A2.208,2.208,0,0,1,3.752,14h112.83a2.208,2.208,0,0,1,2.085,2.314Z" transform="translate(779.333 1283)"/>
                <line y2="7.5" transform="translate(838.5 1369)"/>
                <path d="M39.165,105.49v-4.632c0-2.308,1.576-4.179,3.52-4.179H75.148c1.943,0,3.52,1.871,3.52,4.179v4.632h-39.5Z" transform="translate(779.333 1283)"/>
                <line x2="36" transform="translate(799.334 1314.667)"/>
                <line x2="36" transform="translate(799.334 1321.672)"/>
                <line x2="36" transform="translate(799.334 1328.677)"/>
                <line x2="36" transform="translate(799.334 1335.682)"/>
                <line x2="36" transform="translate(799.334 1342.687)"/>
                <line x2="36" transform="translate(799.334 1349.691)"/>
                <line x2="36" transform="translate(843.369 1314.667)"/>
                <line x2="36" transform="translate(843.369 1321.672)"/>
                <line x2="36" transform="translate(843.369 1328.677)"/>
                <line x2="36" transform="translate(843.369 1335.682)"/>
                <line x2="36" transform="translate(843.369 1342.687)"/>
                <line x2="36" transform="translate(843.369 1349.691)"/>
              </g>
            </svg>
            <h4>Web Platforms</h4>
          </NavLink>
        </li>
        <li className="grid__col-lg-auto grid__col-sm-4 grid__col-6">
          <NavLink to="/publishing-systems">
            <svg id="icon--websites" viewBox="0 0 118.5 92.989">
              <g transform="translate(-1020.25 -1296.25)">
                <path d="M110.25,77.031a1.8,1.8,0,0,1-1.8,1.8H11.05a1.8,1.8,0,0,1-1.8-1.8v-52.4a1.8,1.8,0,0,1,1.8-1.8h97.4a1.8,1.8,0,0,1,1.8,1.8Z" transform="translate(1019.75 1282.168)"/>
                <path d="M118.25,84.517a2.208,2.208,0,0,1-2.085,2.314H3.335A2.208,2.208,0,0,1,1.25,84.517V17.146a2.208,2.208,0,0,1,2.085-2.314h112.83a2.208,2.208,0,0,1,2.085,2.314Z" transform="translate(1019.75 1282.168)"/>
                <line y2="7.5" transform="translate(1078.5 1368.999)"/>
                <path d="M38.748,106.321v-4.632c0-2.308,1.576-4.179,3.52-4.179H74.73c1.943,0,3.52,1.871,3.52,4.179v4.632Z" transform="translate(1019.75 1282.168)"/>
              </g>
            </svg>
            <h4>Publishing Systems</h4>
          </NavLink>
        </li>
        <li className="grid__col-lg-auto grid__col-sm-4 grid__col-6">
          <a href="https://brightwingbooks.booksites.ca/" target="_blank" rel="noopener noreferrer">
            <svg id="icon--bookstore"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88.834 89.498">
              <g transform="translate(-1465.25 -1295.25)">
                <path d="M34.289,92.151c0,1.761-1.129,3.188-2.521,3.188H21.81c-1.392,0-2.52-1.428-2.52-3.188V17.528c0-1.761,1.128-3.188,2.52-3.188h9.958c1.392,0,2.521,1.428,2.521,3.188Z" transform="translate(1448.333 1281.66)"/>
                <path d="M53.3,92.82a2.521,2.521,0,0,1-2.521,2.52H40.825a2.52,2.52,0,0,1-2.52-2.52V33.859a2.52,2.52,0,0,1,2.52-2.52h9.958a2.521,2.521,0,0,1,2.521,2.52Z" transform="translate(1448.333 1281.66)"/>
                <path d="M72.318,92.545A2.669,2.669,0,0,1,69.8,95.34H59.84a2.668,2.668,0,0,1-2.52-2.795V27.135a2.668,2.668,0,0,1,2.52-2.8H69.8a2.669,2.669,0,0,1,2.521,2.8v65.41Z" transform="translate(1448.333 1281.66)"/>
                <path d="M103.467,90.01a2.518,2.518,0,0,1-1.81,3.069l-9.643,2.489a2.518,2.518,0,0,1-3.069-1.81L74.206,36.67A2.519,2.519,0,0,1,76.017,33.6l9.642-2.489a2.519,2.519,0,0,1,3.07,1.81Z" transform="translate(1448.333 1281.66)"/>
                <line x2="87.334" transform="translate(1466 1383.998)"/>
              </g>
            </svg>
            <h4>Bookstore</h4>
          </a>
        </li>
      </ul>
    );
  }
}

export default styled(ServicesNav)`
  width: 85%;
  max-width: 1200px;
  margin: 0 auto;
  li {
    align-items: center;
    padding: 80px 0 75px;
    @media only screen and (max-width: 1200px) {
      padding: 30px 0;
    }
  }
  svg {
    max-height: 91px;
    margin: 0 0 30px;
    transition: all .5s;
    path,
    line,
    circle {
      fill: none;
      stroke: #224275;
      stroke-width: 1.5px;
      transition: all .5s;
    }
    @media only screen and (max-width: 500px) {
      max-height: 80px;
    }
  }
  h4 {
    display: inline-block;
    font-size: 15px;
    text-transform: uppercase;
    padding: 0 0 3px;
    color: var(--brand-color);
    border-bottom: 1px solid transparent;
    transition: all .5s;
  }
  a {
    display: block;
    width: 100%;
    text-align: center;
    &:hover {
      h4 {
        font-size: 16px;
        color: var(--text-color--accent);
        border-color: var(--text-color--accent);
      }
      svg {
        max-height: 80px;
        margin: 11px 0 20px;
        path,
        line,
        circle {
          stroke: var(--text-color--accent);
        }
      }
    }
  }
`;