import React, { Component } from 'react';
import styled from 'styled-components';

import ContentRow from './components/ContentRow';
import SectionCTA from './components/SectionCTA';
import SectionHero from './components/SectionHero';
import SectionIntro from './components/SectionIntro';
import SectionQuote from './components/SectionQuote';
import TextBox from './components/TextBox';

import logoCSS3 from './assets/img/systems/logo--css-3.svg';
import logoFlightdeck from './assets/img/systems/logo--flightdeck.svg';
import logoHTML5 from './assets/img/systems/logo--html-5.svg';
import logoHypothesis from './assets/img/systems/logo--hypothesis.svg';
import logoIllustrator from './assets/img/systems/logo--illustrator.svg';
import logoInDesign from './assets/img/systems/logo--indesign.svg';
import logoPS from './assets/img/systems/logo--photoshop.svg';
import logoPoet from './assets/img/systems/logo--poet.svg';
import logoReadium from './assets/img/systems/logo--readium.png';
import logoSigil from './assets/img/systems/logo--sigil.svg';
import logoStripe from './assets/img/logo--stripe.svg';
import projectMasterCard from './assets/img/systems/project--mastercard.jpg';
import projectRECA from './assets/img/systems/project--reca.jpg';

const ContainerTechnologies = styled.div`
  justify-content: space-around;
  padding: 5px 0 25px;
  background: var(--background--grey);
  figure {
    align-items: center;
    justify-content: center;
    margin: 0 0 45px;
    img {
      width: 85%;
      max-width: 150px;
      max-height: 130px;
    }
  }
`;

class PublishingSystems extends Component {
  componentDidMount() {
    document.title = "Bright Wing Media | Publishing";
  }

  render() {
    return (
      <main className={`${ this.props.className }`}>
        <SectionHero systems heading="Bright Wing Publishing" subheading="Publishing systems for the browser" />
        <SectionIntro
          twoColumns
          heading="Let’s get off track"
        >
          <p>Ever heard the story of why train tracks are the width they are? Because they were made to the width of horse cart tracks. Horse track widths have been the same for millennia because they are the width of a horse’s behind. A publishing system can fall into the same ruts. Many such systems are streamlined for print only, for instance. Some use outdated software, inefficient workflows involving multiple file types of various vintages. There are many facets to a solid, working, and useful publishing workflow, and we specialize in every aspect of them.</p>
          <p>We have some pretty hefty gear-heads working with us too, who love engineering publishing systems for the needs of enterprise-level organizations. We specialize, of course, in the digital part of your system, but we have as much design, layout and print experience as you need too. Ask us about our browser-based reading system for libraries and catalogues, for instance, or about block-chain for publishing. Get in touch and let’s chat about other options than just the train tracks.</p>
        </SectionIntro>
        <ContentRow
          src={projectMasterCard}
          alt="Project Mastercard"
          heading="For instance… HOPE.ENERGY.INGENUITY."
          text="The good people at Page Two Strategies wanted a digital version of an important publication of The MasterCard Foundation. This was a really tough project, and we learned a lot from it. You can view the book yourself of course by clicking over there to your left. Page Two needed the book be accessible by anyone with a browser, and also that it be a dynamic version of the print edition; the Foundation wanted the book accessible by people with legacy phones in Africa, where the artists showcased are from. The result is a beautiful online ebook which performs as well in any screen size as on any smart phone!"
        />
        <ContentRow
          alignRight
          imgFullSize
          src={projectRECA}
          alt="Project RECA"
          heading="For instance… The Real Estate Council of Alberta"
          text="The Real Estate Council have an entire curriculum which they needed to be developed as ebooks and accessible online by the students. Bright Wing designed the landing page, developed the databases, and installed our browser-based reading system so that they could offer their entire course online and their students could access the materials from home or from the bus using their smart phone. We are especially proud of partnership with the Council, and of the work we did with them."
        />
        <TextBox
          bgGrey
          heading="Here are some technologies we work with"
          text=""
        >
        </TextBox>
        <ContainerTechnologies className="grid">
          <figure className="grid__col-md-1 grid__col-sm-2 grid__col-3">
            <img src={logoCSS3} alt="Logo CSS 3" />
          </figure>
          <figure className="grid__col-md-1 grid__col-sm-2 grid__col-3">
            <img src={logoFlightdeck} alt="Logo Flightdeck" />
          </figure>
          <figure className="grid__col-md-1 grid__col-sm-2 grid__col-3">
            <img src={logoHTML5} alt="Logo HTML 5" />
          </figure>
          <figure className="grid__col-md-1 grid__col-sm-2 grid__col-3">
            <img src={logoHypothesis} alt="Logo Hypothesis" />
          </figure>
          <figure className="grid__col-md-1 grid__col-sm-2 grid__col-3">
            <img src={logoIllustrator} alt="Logo Illustrator" />
          </figure>
          <figure className="grid__col-md-1 grid__col-sm-2 grid__col-3">
            <img src={logoInDesign} alt="Logo InDesign" />
          </figure>
          <figure className="grid__col-md-1 grid__col-sm-2 grid__col-3">
            <img src={logoPS} alt="Logo Photoshop" />
          </figure>
          <figure className="grid__col-md-1 grid__col-sm-2 grid__col-3">
            <img src={logoPoet} alt="Logo Poet" />
          </figure>
          <figure className="grid__col-md-1 grid__col-sm-2 grid__col-3">
            <img src={logoReadium} alt="Logo Readium" />
          </figure>
          <figure className="grid__col-md-1 grid__col-sm-2 grid__col-3">
            <img src={logoSigil} alt="Logo Sigil" />
          </figure>
          <figure className="grid__col-md-1 grid__col-sm-2 grid__col-3">
            <img src={logoStripe} alt="Logo Stripe" />
          </figure>
        </ContainerTechnologies>
        <SectionQuote
          quote="I sought out the best interior and cover designer for my non-fiction book. Only then did it occur to me that I should do the same for the e-book, if I wanted to retain the personality and unique design of the interior pages. Enter Bright Wing Media and my introduction to the full potential of the e-book reading experience. More than simply the creation of a digital file, the Game Face e-book is the full typographic expression of a fairly complex layout with ease and flow of text. In the end, my experience was both inspiring and highly rewarding. Full credit goes to the guidance, deft touch and dedication of the e-book specialists at Bright Wing."
          author="Bodine Williams"
          position="Author of Game Face: Mastering the Media Interview - bodinewilliams.com"
          />
        <SectionCTA
          text="The best way to learn about our publishing system is to see it for yourself!"
          btn1Text="Check our Bookstore"
          btn1Url="https://brightwingbooks.booksites.ca/"
          btn1Target="_blank"
        />
      </main>
    );
  }
}

export default PublishingSystems;