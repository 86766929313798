import React, { Component } from 'react';
import styled from 'styled-components';

import Button from './components/Button';
import Container from './components/Container';
import ContentRow from './components/ContentRow';
import SectionCTA from './components/SectionCTA';
import SectionHeading from './components/SectionHeading';
import SectionHero from './components/SectionHero';
import SectionIntro from './components/SectionIntro';
import SectionQuote from './components/SectionQuote';

import bookTrailerImg from './assets/img/film/book-trailer__preview.jpg';
import videoCollage from './assets/img/film/video-collage.jpg';
import heroVideo from './assets/img/film/hero.mp4';

const SectionProcess = styled.section`
  padding: 85px 0 0;
  background: var(--background--grey);
  .section__heading {
    margin: 0 0 65px;
  }
  .list--ordered {
    text-align: left;
    line-height: 1.5;
    li {
      counter-increment: process-step;
      margin: 0 0 45px;
      padding: 0 55px 0 70px;
      color: var(--text-color--dark);
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        font-size: 28px;
        font-weight: var(--fw--bold);
        content: counter(process-step, decimal);
        background: var(--accent-color);
        color: var(--text-color--light);
      }
      h4 {
        text-transform: uppercase;
        margin: 0 0 10px;
      }
      @media only screen and (max-width: 500px) {
        padding: 70px 0 0;
      }
    }
  }
`;

const SectionTrailers = styled.section`
  padding: 85px 0 0;
  div {
    padding: 0 10px;
    @media only screen and (max-width: 991px) {
      &:not(:last-of-type) {
        margin: 0 0 20px;
      }
    }
  }
`;

class Film extends Component {
  componentDidMount() {
    document.title = "Bright Wing Media | Film";
  }

  render() {
    return (
      <main className={`${ this.props.className }`}>
        <SectionHero film heading="Bright Wing Film">
          <video autoPlay loop>
            <source src={heroVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </SectionHero>
        <SectionIntro
          twoColumns
          heading="A World-Class Film Service"
        >
          <p>Bright Wing Film offers beautiful, heart-felt, powerful film services to publishers, organizations, and authors. We have a staff of five with years of experience in every aspect of film production. Need a trailer for your books? Want an informational video for your organization? How about a short film for promotion, or a community-based project? Whatever you have in mind, Bright Wing can help you bring it to the eyes and hearts of your audience.</p>
          <p>Please take a moment to review our video clips and stills on this page or throughout our website. We have hours of clips and footage to choose from, on a large array of subjects, which we can draw upon without having to source generic stock footage. We can also walk you through the whole process of creating and actualizing a film project of any length and kind which will last for years and be something you can be proud of.</p>
        </SectionIntro>
        <ContentRow
          src={bookTrailerImg}
          alt="Book Trailer Preview"
          heading="The power and reach of film"
          text="Cut through the clutter of text with captivating footage and visual story-telling. Some years ago, Bright Wing staff perused the book trailers for a Canadian book industry website and realized that (no offence) most of the videos were pretty amateur and simply did not do the books justice. Bright Wing Film began at that moment because we realized we could make beautiful short films for authors and publishers to promote their publications, and we could do it at a reasonable cost. Things grew from there! Now we make short films of all kinds and now even a feature-length documentary! The power of visual story-telling translates into action, engagement, and sales for every project we are engaged for. We have a marketing team to help you get the trailer/video/film to the audience it deserves. Get in touch and let’s talk film!"
        />
        <ContentRow
          alignRight
          src={videoCollage}
          alt="Video Stills"
          heading="Our Treasures, Your Project"
          text="Bright Wing Film has collected hundreds of hours of beautiful, heart-felt, and professional video and b-roll over the years. All of this footage, and the stills we can capture from it, is available for your project as well. Why is this useful? By pulling from our footage for your film project, you can save money by avoiding purchasing stock footage from expensive websites; furthermore, stock footage is just that - ‘stock,’ impersonal, corporate - whereas our footage is created personally, with heart, and and we own the rights. Ask us about our video libraries and how to access them!"
        >
          <Button url="mailto:aloha@brightwing.ca" text="Contact Us" />
        </ContentRow>
        <SectionProcess>
          <Container>
            <SectionHeading text="A peek into the process" />
            <ol className="grid list--ordered">
              <li className="grid__col-md-6 grid__col-12">
                <h4>Discovery</h4>
                <p>This first step is a vital one, and it is all about you. We meet with you and learn about your book, your vision for the trailer, what you feel is the most important message for the video, and more. This is also a time for you to ask all your questions about what we do and how we do it.</p>
              </li>
              <li className="grid__col-md-6 grid__col-12">
                <h4>Proposal</h4>
                <p>Shortly after our first meeting with you, we will put together a proposal to consider. This proposal will include sample video ideas, a very rough story board, a list of what we might need from you, and various ways we find to keep costs down. Of course, we also provide a rate for the project. Lastly, we provide a suggested project schedule.</p>
              </li>
              <li className="grid__col-md-6 grid__col-12">
                <h4>Pre-production</h4>
                <p>If you approve the proposal, our very next step is to provide you with a shot by shot storyboard. This usually involves another meeting and it is a chance for you to see in detail, discuss, and make changes to what our creative team has put together for your book trailer.</p>
              </li>
              <li className="grid__col-md-6 grid__col-12">
                <h4>Production</h4>
                <p>With our storyboards in place, our scenes mapped out, we go and do the work of timing and editing your trailer. This is the really fun part!</p>
              </li>
              <li className="grid__col-md-6 grid__col-12">
                <h4>Review</h4>
                <p>Once we have finished filming, editing, and compiling the trailer, you get your first view of the trailer! Get your popcorn! Your feedback at this point is critical because it will applied to the final version. This initial viewing is meant as a way for you to get a sense of how it all looks and works. It is an exciting moment.</p>
              </li>
              <li className="grid__col-md-6 grid__col-12">
                <h4>Final cut and (gasp!) release</h4>
                <p>This is best part of the process because this is when we send you the final video files in all the formats you need to use it and showcase your book anywhere on the web and social media. Congratulations! You now have a great promotional tool which will enhance engagement of your book for years to come.</p>
              </li>
            </ol>
          </Container>
        </SectionProcess>
        <SectionTrailers>
          <Container className="grid">
            <div className="grid__col-md-3 grid__col-sm-6 grid__col-sm-6 grid__col-12">
              <iframe title="Video - To Hell and Back" src="https://player.vimeo.com/video/229881117?title=0&byline=0&portrait=0" frameBorder="0" allowFullScreen></iframe>
            </div>
            <div className="grid__col-md-3 grid__col-sm-6 grid__col-sm-6 grid__col-12">
              <iframe title="Video - Discover YBYG" src="https://player.vimeo.com/video/300876244?title=0&byline=0&portrait=0" frameBorder="0" allowFullScreen></iframe>
            </div>
            <div className="grid__col-md-3 grid__col-sm-6 grid__col-sm-6 grid__col-12">
              <iframe title="Video - Think Outside - Rocky Mountain Books" src="https://player.vimeo.com/video/170529079?title=0&byline=0&portrait=0" frameBorder="0" allowFullScreen></iframe>
            </div>
            <div className="grid__col-md-3 grid__col-sm-6 grid__col-sm-6 grid__col-12">
              <iframe title="Video - An Altar in the Wilderness" src="https://player.vimeo.com/video/150448967?title=0&byline=0&portrait=0" frameBorder="0" allowFullScreen></iframe>
            </div>
          </Container>
        </SectionTrailers>
        <SectionCTA
          text="To view more of our work, visit our Vimeo channel"
          btn1Text="BWB Vimeo"
          btn1Url="https://vimeo.com/brightwingbooks"
          btn1Target="_blank"
        />
        <SectionQuote
          quote="After seeing what Kaleeg and his team are capable of, I fired myself from doing ebook conversions and hired Bright Wing instead. Their style sheets are beautiful and their code jumps through every hoop of digital distribution. Kaleeg and his team are accommodating, masterful and lovely people to work with. They’ve saved me countless hours of toil and troubleshooting, and have made the digital publishing side of my job a piece of cake."
          author="Lara Smith"
          position="Managing Editor - Figure 1 Publishers"
        />
      </main>
    );
  }
}

export default Film;