import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import Container from './Container';
import MenuBookstore from './MenuBookstore';
import MenuSocial from './MenuSocial';

const FooterNav = styled.div`
  flex-direction: row;
  .grid {
    justify-content: space-between;
  }
  h4 {
    margin: 0 0 5px;
  }
  address {
    font-style: normal;
    @media only screen and (max-width: 767px) {
      margin: 30px 0 0;
    }
  }
  a {
    color: inherit;
    transition: all .3s;
    &:hover {
      color: var(--text-color--accent);
    }
  }
`;

const FooterCopy = styled.div`
  padding: 15px 0;
  background: var(--accent-color);
  .grid {
    justify-content: space-between;
  }
  @media only screen and (min-width: 992px) {
    .copy--property {
      text-align: right;
    }
  }
  @media only screen and (max-width: 991px) {
    .copy--property {
      margin: 15px 0 0;
    }
  }
`;

class Footer extends Component {
  render() {
    return (
      <footer className={`${ this.props.className }`}>
        <Container className="grid footer__info">
          <FooterNav className="grid__col-lg-6 grid__col-md-7 grid__col-12">
            <div className="grid">
              <ul className="grid__col-sm-3 grid__col-12">
                <h4>Bright Wing</h4>
                <li>
                  <NavLink to="/ebooks" activeClassName="active">Ebooks</NavLink>
                </li>
                <li>
                  <NavLink to="/audiobooks" activeClassName="active">Audiobooks</NavLink>
                </li>
                <li>
                  <NavLink to="/film" activeClassName="active">Film</NavLink>
                </li>
                <li>
                  <NavLink to="/web-platforms" activeClassName="active">Web Platforms</NavLink>
                </li>
                <li>
                  <NavLink to="/publishing-systems" activeClassName="active">Publishing Systems</NavLink>
                </li>
                <li>
                  <NavLink to="/about-us" activeClassName="active">About Us</NavLink>
                </li>
                <li>
                  <NavLink to="/connect" activeClassName="active">Connect</NavLink>
                </li>
              </ul>
              <address className="grid__col-sm-4 grid__col-12">
                <h4>Connect</h4>
                <p><a href="tel:+17788740198">778 874 0198</a></p>
                <p><a href="mailto:aloha@brightwing.ca">aloha[at]brightwing.ca</a></p>
              </address>
              <ul className="grid__col-sm-4 grid__col-12 menu--store">
                <MenuBookstore />
              </ul>
            </div>
          </FooterNav>
          <div className="grid__col-lg-6 grid__col-md-5 grid__col-12">
            <MenuSocial hoverAnimation />
          </div>
        </Container>
        <FooterCopy>
          <Container className="grid">
            <p className="grid__col-md-5 grid__col-12">Bright Wing Media © 2019 | All Rights Reserved</p>
            <p className="grid__col-md-6 grid__col-12 copy--property">All imagery and video produced and property of Bright Wing Film</p>
          </Container>
        </FooterCopy>
      </footer>
    );
  }
}

export default styled(Footer)`
  font-size: 14px;
  line-height: 1.5;
  background: var(--brand-color);
  color: var(--text-color--light);
  ul li,
  h4,
  p {
    font-size: 14px;
    line-height: 1.75;
  }
  ul li,
  p {
    font-weight: var(--fw--light);
  }
  .footer__info {
    padding: 80px 0 70px; 
  }
  @media only screen and (max-width: 991px) {
    .menu--social {
      margin: 45px 0 0;
    }
  }
  @media only screen and (max-width: 767px) {
    .menu--store {
      display: none;
    }
  }
`;