import React, { Component } from 'react';
import styled, {css} from 'styled-components';

import Container from './Container';
import SectionSubheading from './SectionSubheading';

class SectionIntro extends Component {
  render() {
    return (
      <section className={`${ this.props.className }`}>
        <Container sizeSmall>
          <SectionSubheading uppercase text={this.props.heading} />
          <article>
            {this.props.children}
          </article>
        </Container>
      </section>
    );
  }
}

export default styled(SectionIntro)`
  padding: 80px 0 85px;
  background: var(--brand-color);
  color: var(--text-color--light);
  .section__subheading {
    text-align: center;
    color: inherit;
  }
  article {
    margin: 25px 0 0;
  }
  p:not(:last-child) {
    margin: 0 0 25px;
  }
  ${props => props.twoColumns && css`
    @media only screen and (min-width: 992px) {
      .container article {
        display: flex;
        justify-content: space-between;
      }
      p {
        width: 45%;
      }
    }
  `}
`;