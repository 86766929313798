import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import Button from './components/Button';
import Container from './components/Container';
import FormField from './components/FormField';
import SectionHeading from './components/SectionHeading';
import SectionSubheading from './components/SectionSubheading';
import SectionHero from './components/SectionHero';

import imgChicago from './assets/img/connect/chicago.jpg';
import imgNelson from './assets/img/connect/nelson.jpg';
import imgToronto from './assets/img/connect/toronto.jpg';
import imgVancouver from './assets/img/connect/vancouver.jpg';
import logoSlack from './assets/img/logo--slack.svg';
import logoPayPal from './assets/img/logo--paypal.svg';
import logoStripe from './assets/img/logo--stripe.svg';

const ButtonSubmit = styled.button`
  display: block;
  width: 160px;
  font-size: 14px;
  font-weight: var(--fw--bold);
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  padding: 11px 25px;
  background: var(--accent-color);
  color: var(--text-color--light);
  border: 2px solid var(--accent-color);
  transition: all .35s;
  &:hover {
    cursor: pointer;
    background: transparent;
    color: var(--accent-color);
  }
`;

const SectionForm = styled.section`
  padding: 80px 0 95px;
  address {
    font-style: normal;
    margin: 0 0 50px;
    p {
      font-size: 16px;
    }
    a {
      color: var(--text-color--dark);
      transition: color .3s;
      &:hover {
        color: var(--text-color--accent);
      }
    }
  }
  p {
    max-width: 390px;
  }
  @media only screen and (min-width: 768px) {
    form {
      padding: 0 30px 0 0;
    }
    article {
      padding: 0 0 0 30px;
    }
  }
  @media only screen and (max-width: 767px) {
    form {
      margin: 0 0 65px;
    }
  }
`;

const SectionSlack = styled.section`
  padding: 60px 0 80px;
  background: var(--background--grey);
  figure {
    align-items: flex-start;
    img {
      max-width: 275px;
    }
    @media only screen and (max-width: 767px) {
      margin: 0 0 35px;
    }
  }
  .section__heading,
  p:not(:last-child) {
    margin: 0 0 15px;
  }
  a {
    color: var(--text-color--accent);
    border-bottom: 1px solid transparent;
    transition: border .5s;
    &:hover {
      border-bottom: 1px solid var(--text-color--accent);
    }
  }
  p {
    max-width: 750px;
  }
`;

const SectionOffice = styled.section`
  padding: 80px 0 90px;
  .section__blurb {
    margin: 25px 0 45px;
  }
  figure {
    margin: 0 0 65px;
    img {
      max-width: 90%;
      margin: 40px 0 0;
    }
    &.office--van {
      img {
        margin: 10px 0 0;
      }
    }
  }
`;

const SectionPaymentMethods = styled.section`
  padding: 40px 0 50px;
  background: var(--accent-color);
  color: var(--text-color--light);
  .section__heading {
    color: inherit;
    margin: 0 0 25px;
  }
  address {
    font-style: normal;
    p:first-child {
      margin: 0 0 15px;
    }
  }
  .btn {
    max-width: 175px;
    margin: 35px 30px 0 0;
  }
  @media only screen and (max-width: 767px) {
    address {
      margin: 0 0 35px;
    }
  }
`;

class Connect extends Component {
  componentDidMount() {
    document.title = "Bright Wing Media | Connect";
  }

  render() {
    return (
      <main className={`${ this.props.className }`}>
        <SectionHero connect heading="Connect with us" />
        <SectionForm>
          <Container className="grid">
            <form className="grid__col-sm-6 grid__col-12" method="POST" action="https://formspree.io/aloha@brightwing.ca">
              <FormField label="Name *">
                <input className="form__input" type="text" name="name" />
              </FormField>
              <FormField label="Email *">
                <input className="form__input" type="email" name="email" />
              </FormField>
              <FormField label="Subject">
                <input className="form__input" type="text" name="subject" />
              </FormField>
              <FormField label="Message">
                <textarea className="form__input" name="message"></textarea>
              </FormField>
              <input type="hidden" name="_next" value="https://brightwing.ca/thank-you" />
              <ButtonSubmit type="submit">Connect</ButtonSubmit>
            </form>
            <article className="grid__col-sm-6 grid__col-12">
              <address>
                <p><strong><a href="tel:+17788740198">1 (778) 874-0198</a></strong></p>
                <p><strong><a href="mailto:aloha@brightwing.ca">aloha[at]brightwing.ca</a></strong></p>
              </address>
              <p>Interested in a quote? Please provide as much detail about your project as possible. For ebooks: page count, number of images, figures, index, footnotes, endnotes, etc. If a pdf can be provided that is even better! For websites, let us know the number of pages and what kind of content you will be providing. Thank you! The more detail you can provide, the quicker we can provide you with an accurate estimate.</p>
            </article>
          </Container>
        </SectionForm>
        <SectionSlack>
          <Container className="grid">
            <figure className="grid__col-sm-4 grid__col-12">
              <img src={logoSlack} alt="Logo Slack" />
            </figure>
            <article className="grid__col-sm-8 grid__col-12">
              <SectionHeading text="Our client content management system" />
              <p>Bright Wing Media uses <a href="https://slack.com/" target="_blank" rel="noopener noreferrer">#Slack</a> as our content management system and client support area. <a href="https://slack.com/" target="_blank" rel="noopener noreferrer">Slack</a> is ideal for our team and we think you will like it too. We are able to communicate directly, in real time if need be, and to transfer files back and forth. In addition, the entire exchange is archived and files can be retrieved at any time.</p>
              <p>If you are a new client or want to be one, you can request access to your private <a href="https://slack.com/" target="_blank" rel="noopener noreferrer">Slack</a> channel by providing your name, email, and project title <NavLink to="/connect">in this form</NavLink>. We will set you up with a private workspace with us. Questions? Call or email. Always to good to hear from you.</p>
            </article>
          </Container>
        </SectionSlack>
        <SectionOffice>
          <Container>
            <SectionHeading center text="Where are we?" />
            <p className="section__blurb">We are a ‘distributed office’, which basically means we’d happily meet you for a coffee if you live:</p>
            <div className="grid">
              <figure className="grid__col-sm-6 grid__col-12 office--van">
                <SectionSubheading text="Vancouver" />
                <p>- Home Base -</p>
                <img src={imgVancouver} alt="Vancouver" />
              </figure>
              <figure className="grid__col-sm-6 grid__col-12">
                <SectionSubheading text="Chicago" />
                <img src={imgChicago} alt="Chicago" />
              </figure>
              <figure className="grid__col-sm-6 grid__col-12">
                <SectionSubheading text="Toronto" />
                <img src={imgToronto} alt="Toronto" />
              </figure>
              <figure className="grid__col-sm-6 grid__col-12">
                <SectionSubheading text="Nelson" />
                <img src={imgNelson} alt="Nelson" />
              </figure>
            </div>
          </Container>
        </SectionOffice>
        <SectionPaymentMethods>
          <Container className="grid">
            <SectionHeading className="grid__col-12" text="Secure payment methods" />
            <address className="grid__col-md-3 grid__col-sm-4 grid__col-12">
              <p><strong>Cheques payable to:</strong></p>
              <p>Bright Wing Media</p>
              <p>2552 East 6th Ave</p>
              <p>Vancouver, BC  V5M 1R2</p>
            </address>
            <article className="grid__col-md-9 grid__col-sm-8 grid__col-12">
              <p><strong>Online through:</strong></p>
              <div className="grid">
                <Button className="grid__col-sm-auto grid__col-12" secondary url="https://www.paypal.com/ca/webapps/mpp/home" target="_blank">
                  <img src={logoPayPal} alt="Logo PayPal" />
                </Button>
                <Button className="grid__col-sm-auto grid__col-12" secondary url="/" target="_blank">
                  <img src={logoStripe} alt="Logo Stripe" />
                </Button>
              </div>
            </article>
          </Container>
        </SectionPaymentMethods>
      </main>
    );
  }
}

export default Connect;