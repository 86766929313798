import React, { Component } from 'react';
import styled, {css} from 'styled-components';

class Button extends Component {
  render() {
    return (
      <a href={this.props.url} target={this.props.target} className={`${ this.props.className + ' btn'}`} onClick={this.props.onClick}>
        {this.props.text}  
        {this.props.children}  
      </a>
    );
  }
}

export default styled(Button)`
  display: block;
  min-width: 160px;
  max-width: 300px;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  margin: ${props => props.marginTop ? '35px 0 0' : ''};
  padding: 13px 25px;
  background: var(--accent-color);
  color: var(--text-color--light);
  border: 2px solid var(--accent-color);
  transition: all .35s;
  img {
    max-height: 32px;
  }
  &:hover {
    cursor: pointer;
    background: transparent;
    color: var(--accent-color);
  }
  ${props => props.secondary && css`
    padding: 8px 25px;
    background: var(--text-color--light);
    color: var(--accent-color);
    border-radius: 30px;
    &:hover {
      background: var(--text-color--light);
      color: var(--text-color--light);
      border-color: var(--brand-color);
    }
  `}
`;